export function hasCookie(cookieName: string) {
    return Object.keys(getCookies()).includes(cookieName);
}

export function setCookie(name: string, value: unknown, days = 1) {
    let expires;
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}

export function setCookies(cookies: { [s: string]: unknown } | ArrayLike<unknown>) {
    Object.entries(cookies).forEach((entry) => {
        const [name, value] = entry;
        setCookie(name, value);
    });
}

export function getCookie(search: string) {
    return document.cookie
        .split(';')
        .map((item) => {
            const [name, value] = item.split(/\s?(.*?)=(.*)/).splice(1, 2);
            if (name === search) {
                return value;
            }
        })
        .filter((item) => item !== undefined)
        .shift();
}

type Cookie = {
    [key: string]: string | number;
};

export function getCookies() {
    return document.cookie.split(';').reduce<Cookie>((prev, current) => {
        const [name, value] = current.split(/\s?(.*?)=(.*)/).splice(1, 2);
        prev[name] = value;
        return prev;
    }, {});
}
